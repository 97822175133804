
import { errorCallback } from "@/helpers/helpers";
import { hideLoader, showLoader } from "@/helpers/loader";
import { TddEvent } from "@/models/TddEvent";
import router from "@/router";
import { EVENTS_ACTIONS, EVENTS_GETTERS } from "@/store/modules/events";
import moment from "moment";
import { FilterMatchMode } from "primevue/api";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { computed, defineComponent, nextTick, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "EventsListView",

  setup() {
    const confirm = useConfirm();
    const store = useStore();
    const toast = useToast();

    /**
     * DATA
     */
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      title: { value: null, matchMode: FilterMatchMode.CONTAINS },
      "location.name": { value: null, matchMode: FilterMatchMode.CONTAINS },
      "organizer.name": { value: null, matchMode: FilterMatchMode.CONTAINS },
      start_at: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const filterStatuses = [true, false];
    const rowsNumber = ref(0);

    /*
     * COMPUTED
     */
    const events = computed(() => store.getters[EVENTS_GETTERS.ALL]);

    /*
     * METHODS
     */
    const confirmDelete = function (event: Event, id: string) {
      confirm.require({
        target: event.currentTarget as HTMLElement,
        message: "Sicuro di voler eliminare l'evento?",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          performDelete(id);
        },
        reject: () => {
          //callback to execute when project rejects the action
        },
      });
    };

    const performDelete = function (id: string) {
      store
        .dispatch(EVENTS_ACTIONS.DELETE, id)
        .then(() => {
          fetchEvents();
        })
        .catch((err) => {
          errorCallback(toast, err);
        })
        .finally(() => {
          hideLoader(store);
        });
    };

    const fetchEvents = async function () {
      return store.dispatch(EVENTS_ACTIONS.FETCH_ALL);
    };

    const filterCallback = function (event: any) {
      rowsNumber.value = event.filteredValue.length;
    };

    const from_to = function (ev: TddEvent): string {
      return ev.start_at && ev.end_at
        ? `${moment(ev.start_at).format("HH:mm")} - ${moment(ev.end_at).format(
            "HH:mm"
          )}`
        : "";
    };

    const goToEventDetail = function (eventId: string) {
      // --> /eventi/:id
      router.push({ name: "eventDetail", params: { id: eventId } })
    };

    const goToNewEvent = function () {
      // --> /eventi/nuovo
      router.push({ name: "newEvent" })
    };

    /**
     * INIT
     */
    showLoader(store);
    nextTick(async () => {
      // DOM is now updated
      await fetchEvents().finally(() => {
        hideLoader(store);
      });
    });

    return {
      moment,

      // DATA
      filters,
      filterStatuses,
      rowsNumber,

      // COMPUTED
      events,

      // METHODS
      confirmDelete,
      fetchEvents,
      filterCallback,
      from_to,
      goToEventDetail,
      goToNewEvent,
    };
  },
});


import { computed, defineComponent, nextTick, ref } from "vue";
import { useStore } from "vuex";
import {
  ORGANIZERS_ACTIONS,
  ORGANIZERS_GETTERS,
} from "@/store/modules/organizers";
import { hideLoader, showLoader } from "@/helpers/loader";
import { FilterMatchMode } from "primevue/api";
import { errorCallback, showToast } from "@/helpers/helpers";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { Organizer } from "@/models/Organizer";

export default defineComponent({
  name: "OrganizersListView",

  setup() {
    const confirm = useConfirm();
    const store = useStore();
    const toast = useToast();

    /**
     * DATA
     */
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const filterStatuses = [true, false];

    const form = ref({
      name: {
        error: "",
        rules: { required: true },
        val: "",
      },

      description: {
        error: "",
        rules: { required: false },
        val: "",
      },

      link: {
        error: "",
        rules: { required: false },
        val: "",
      },

      link_2: {
        error: "",
        rules: { required: false },
        val: "",
      },

      link_3: {
        error: "",
        rules: { required: false },
        val: "",
      },

      logo_url: {
        error: "",
        rules: { required: false },
        val: "",
      },
    });
    const isFormVisible = ref(false);
    const selectedOrganizer = ref<Organizer | null>(null);
    const rowsNumber = ref(0);
    const validateForm = ref(false);

    /*
     * COMPUTED
     */
    const isFormValid = computed(() => {
      return form.value.name.error === "";
    });
    const organizers = computed(() => store.getters[ORGANIZERS_GETTERS.ALL]);

    /*
     * METHODS
     */
    const cleanUpForm = function () {
      form.value.name.val = "";
      form.value.name.error = "";

      form.value.description.val = "";
      form.value.description.error = "";

      form.value.link.val = "";
      form.value.link.error = "";

      form.value.link_2.val = "";
      form.value.link_2.error = "";

      form.value.link_3.val = "";
      form.value.link_3.error = "";

      form.value.logo_url.val = "";
      form.value.logo_url.error = "";
    };

    const confirmDelete = function (event: Event, id: string) {
      confirm.require({
        target: event.currentTarget as HTMLElement,
        message: "Sicuro di voler eliminare l'organizzatore?",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          performDelete(id);
        },
        reject: () => {
          //callback to execute when project rejects the action
        },
      });
    };

    const fetchOrganizers = async function () {
      return store.dispatch(ORGANIZERS_ACTIONS.FETCH_ALL);
    };

    const filterCallback = function (organizer: any) {
      rowsNumber.value = organizer.filteredValue.length;
    };

    const openForm = function (organizer: Organizer | null) {
      cleanUpForm();

      selectedOrganizer.value = organizer;

      if (organizer != null) {
        form.value.name.val = organizer.name;
        form.value.description.val = organizer.description || "";
        form.value.link.val = organizer.link || "";
        form.value.link_2.val = organizer.link_2 || "";
        form.value.link_3.val = organizer.link_3 || "";
        form.value.logo_url.val = organizer.logo_url || "";
      }

      isFormVisible.value = true;
    };

    const performDelete = function (id: string) {
      store
        .dispatch(ORGANIZERS_ACTIONS.DELETE, id)
        .then(() => {
          isFormVisible.value = false;

          fetchOrganizers();
          showToast(toast, {
            message: "Organizzatore eliminato correttamente",
            title: "Organizzatore",
          });
        })
        .catch((err) => {
          errorCallback(toast, err);
        })
        .finally(() => {
          hideLoader(store);
        });
    };

    const submit = function () {
      if (!isFormValid.value) {
        return;
      }

      const params = {
        organizer: {
          id: selectedOrganizer.value?.id,
          name: form.value.name.val,
          description: form.value.description.val,
          link: form.value.link.val,
          link_2: form.value.link_2.val,
          link_3: form.value.link_3.val,
          logo_url: form.value.logo_url.val,
        },
      };

      showLoader(store);

      store
        .dispatch(
          selectedOrganizer.value?.id != null
            ? ORGANIZERS_ACTIONS.UPDATE
            : ORGANIZERS_ACTIONS.CREATE,
          params
        )
        .then(() => {
          hideLoader(store);
          isFormVisible.value = false;
          fetchOrganizers();

          showToast(toast, {
            message: "Organizzatore salvato correttamente",
            title: "Organizzatore",
          });
        })
        .catch((err) => {
          hideLoader(store);
          errorCallback(toast, err);
        });
    };

    const updateFormInputText = function ({ error, name, value }) {
      form.value[`${name}`].error = error;
      form.value[`${name}`].val = value;
    };

    /**
     * INIT
     */
    showLoader(store);
    nextTick(async () => {
      // DOM is now updated
      await fetchOrganizers();
      hideLoader(store);
    });

    return {
      // DATA
      confirm,
      filters,
      filterStatuses,
      form,
      isFormVisible,
      selectedOrganizer,
      rowsNumber,

      // COMPUTED
      organizers,

      // METHODS
      confirmDelete,
      fetchOrganizers,
      filterCallback,
      openForm,
      submit,
      updateFormInputText,
      validateForm,
    };
  },
});

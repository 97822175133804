import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "main-content",
  class: "flex flex-column w-100 h-full"
}
const _hoisted_2 = { class: "p-4" }
const _hoisted_3 = { class: "p-d-flex p-p-4" }
const _hoisted_4 = { class: "p-pl-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ConfirmPopup = _resolveComponent("ConfirmPopup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_ConfirmPopup, null, {
      message: _withCtx((slotProps) => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("i", {
            class: _normalizeClass(slotProps.message.icon),
            style: {"font-size":"1.5rem"}
          }, null, 2),
          _createElementVNode("p", _hoisted_4, _toDisplayString(slotProps.message.message), 1)
        ])
      ]),
      _: 1
    })
  ]))
}
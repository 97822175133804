import Vuex from 'vuex'
import appModule, { AppState } from './modules/app'
import authModule, { AuthState } from './modules/auth'
import eventsModule, { EventsState } from './modules/events';
import locationModule, { LocationsState } from './modules/locations';
import organizerModule, { OrganizersState } from './modules/organizers';

export interface State {
  app: AppState;
  auth: AuthState;
  events: EventsState;
  locations: LocationsState;
  organizers: OrganizersState,
}

export const store = new Vuex.Store<State>({
  strict: true,
  modules: {
    app: appModule,
    auth: authModule,
    events: eventsModule,
    locations: locationModule,
    organizers: organizerModule,
  }
})
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5985610c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-sm w-100" }
const _hoisted_2 = { class: "p-input-icon-right mt-1 w-100" }
const _hoisted_3 = {
  key: 0,
  class: "pi pi-lock"
}
const _hoisted_4 = {
  key: 1,
  class: "pi pi-euro"
}
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`mb-3 p-field ${
      _ctx.inputSize == 'small'
        ? 'p-inputtext-sm'
        : _ctx.inputSize == 'large'
        ? 'p-inputtext-lg'
        : ''
    }`)
  }, [
    _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.label), 1),
    _createElementVNode("span", _hoisted_2, [
      (_ctx.disabled)
        ? (_openBlock(), _createElementBlock("i", _hoisted_3))
        : _createCommentVNode("", true),
      (_ctx.name === 'price')
        ? (_openBlock(), _createElementBlock("i", _hoisted_4))
        : _createCommentVNode("", true),
      _createVNode(_component_InputText, {
        ref: "input",
        name: _ctx.name,
        type: _ctx.type,
        disabled: _ctx.disabled,
        value: _ctx.val,
        class: _normalizeClass(_ctx.width),
        placeholder: _ctx.placeholder,
        onChange: _ctx.onChange,
        onInput: _ctx.onChange
      }, null, 8, ["name", "type", "disabled", "value", "class", "placeholder", "onChange", "onInput"])
    ]),
    (_ctx.validationEnabled)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(`text-xs mt-1 ${_ctx.inputSize == 'large' ? 'mb-5' : 'mb-3'} p-error`),
          innerHTML: `${_ctx.error !== '' ? _ctx.error : ' '}`
        }, null, 10, _hoisted_5))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(`text-xs mt-1 ${_ctx.inputSize == 'large' ? 'mb-5' : 'mb-3'} p-error`)
        }, "   ", 2))
  ], 2))
}
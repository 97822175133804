import { ToastServiceMethods } from "primevue/toastservice";

function showToast(toast: ToastServiceMethods, data: { message: string, error?: boolean, title?: string }) {
  toast.add({
    detail: data.message,
    life: 3000,
    severity: data.error ? "error" : "success",
    summary: data.error ? "Errore" : data.title,
  });
}

export {
  showToast,
}

import { computed, defineComponent, nextTick, ref } from "vue";
import { useStore } from "vuex";
import {
  LOCATIONS_ACTIONS,
  LOCATIONS_GETTERS,
} from "@/store/modules/locations";
import { hideLoader, showLoader } from "@/helpers/loader";
import { FilterMatchMode } from "primevue/api";
import { errorCallback, showToast } from "@/helpers/helpers";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { Location } from "@/models/Location";

export default defineComponent({
  name: "LocationsListView",

  setup() {
    const confirm = useConfirm();
    const store = useStore();
    const toast = useToast();

    /**
     * DATA
     */
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const filterStatuses = [true, false];
    const form = ref({
      name: {
        error: "",
        rules: { required: true },
        val: "",
      },

      address_line_1: {
        error: "",
        rules: { required: false },
        val: "",
      },

      address_line_2: {
        error: "",
        rules: { required: false },
        val: "",
      },

      latitude: {
        error: "",
        rules: { required: false },
        val: "",
      },

      longitude: {
        error: "",
        rules: { required: false },
        val: "",
      },
    });
    const isFormVisible = ref(false);
    const selectedLocation = ref<Location | null>(null);
    const rowsNumber = ref(0);
    const validateForm = ref(false);

    /*
     * COMPUTED
     */

    const isFormValid = computed(() => {
      return form.value.name.error === "";
    });
    const locations = computed(() => store.getters[LOCATIONS_GETTERS.ALL]);

    /*
     * METHODS
     */
    const cleanUpForm = function () {
      form.value.name.val = "";
      form.value.name.error = "";

      form.value.latitude.val = "";
      form.value.latitude.error = "";

      form.value.longitude.val = "";
      form.value.longitude.error = "";

      form.value.address_line_2.val = "";
      form.value.address_line_2.error = "";

      form.value.address_line_1.val = "";
      form.value.address_line_1.error = "";
    };

    const confirmDelete = function (event: Event, id: string) {
      confirm.require({
        target: event.currentTarget as HTMLElement,
        message: "Sicuro di voler eliminare la location?",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          performDelete(id);
        },
        reject: () => {
          //callback to execute when project rejects the action
        },
      });
    };

    const fetchLocations = async function () {
      return store.dispatch(LOCATIONS_ACTIONS.FETCH_ALL);
    };

    const filterCallback = function (location: any) {
      rowsNumber.value = location.filteredValue.length;
    };

    const openForm = function (location: Location | null) {
      cleanUpForm();

      selectedLocation.value = location;

      if (location != null) {
        form.value.name.val = location.name;
        form.value.address_line_1.val = location.address_line_1 || "";
        form.value.address_line_2.val = location.address_line_2 || "";
        form.value.latitude.val = `${location.latitude}`;
        form.value.longitude.val = `${location.longitude}`;
      }

      isFormVisible.value = true;
    };

    const performDelete = function (id: string) {
      store
        .dispatch(LOCATIONS_ACTIONS.DEELETE, id)
        .then(() => {
          isFormVisible.value = false;

          fetchLocations();
          showToast(toast, {
            message: "Location eliminata correttamente",
            title: "Location",
          });
        })
        .catch((err) => {
          errorCallback(toast, err);
        })
        .finally(() => {
          hideLoader(store);
        });
    };

    const submit = function () {
      if (!isFormValid.value) {
        return;
      }

      const params = {
        location: {
          id: selectedLocation.value?.id,
          name: form.value.name.val,
          address_line_1: form.value.address_line_1.val,
          address_line_2: form.value.address_line_2.val,
          latitude: form.value.latitude.val,
          longitude: form.value.longitude.val,
        },
      };

      showLoader(store);

      store
        .dispatch(
          selectedLocation.value
            ? LOCATIONS_ACTIONS.UPDATE
            : LOCATIONS_ACTIONS.CREATE,
          params
        )
        .then(() => {
          hideLoader(store);
          isFormVisible.value = false;
          fetchLocations();
        })
        .catch((err) => {
          hideLoader(store);
          errorCallback(toast, err);
        });
    };

    const updateFormInputText = function ({ error, name, value }) {
      form.value[`${name}`].error = error;
      form.value[`${name}`].val = value;
    };

    /**
     * INIT
     */
    showLoader(store);
    nextTick(async () => {
      // DOM is now updated
      await fetchLocations();
      hideLoader(store);
    });

    return {
      // DATA
      confirm,
      filters,
      filterStatuses,
      form,
      isFormVisible,
      selectedLocation,
      rowsNumber,

      // COMPUTED
      locations,

      // METHODS
      confirmDelete,
      fetchLocations,
      filterCallback,
      openForm,
      submit,
      updateFormInputText,
      validateForm,
    };
  },
});

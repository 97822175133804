import { LocationService } from "@/services/api/location-service";

export const LOCATIONS_ACTIONS = {
  FETCH_ALL: 'locations/fetchAll',
  CREATE: 'locations/createLocation',
  UPDATE: 'locations/updateLocation',
  DEELETE: 'locations/deleteLocation',
};

export const LOCATIONS_GETTERS = {
  ALL: 'locations/all',
}

export interface LocationsState {
  all: Array<Location>,
}

export default {
  namespaced: true,

  state: (): LocationsState => ({
    all: [],
  }),

  actions: {
    async fetchAll(context: any) {
      await LocationService.allLocations().then(
        locations => {
          context.commit('setLocations', locations);
        },
        error => {
          context.commit('setLocations', []);
          return Promise.reject(error);
        });
    },

    async createLocation(context: any, params: Map<string, any>) {
      await LocationService.createLocation(params);
    },

    async deleteLocation(context: any, id: string) {
      await LocationService.delete(id);
    },

    async updateLocation(context: any, params: Map<string, any>) {
      await LocationService.updateLocation(params['location']['id'] as string, params);
    }

  },

  getters: {
    all: (state: LocationsState): Array<Location> => state.all,
  },

  mutations: {
    setLocations(state: LocationsState, locations: Array<Location>) {
      state.all = locations;
    },
  }
}

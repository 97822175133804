import { TddEvent } from "@/models/TddEvent";
import { EventService } from "@/services/api/event-service";
import { State } from "vuex-class";


export const EVENTS_GETTERS = {
  ALL: 'events/all',
  EVENT: 'events/event',
  EVENT_TYPES: 'events/eventTypes',
  TAGS: 'events/tags',
};

export const EVENTS_ACTIONS = {
  FETCH_ALL: 'events/fetchAll',
  CREATE: 'events/createEvent',
  DETAIL: 'events/eventDetail',
  UPDATE: 'events/updateEvent',
  DELETE: 'events/deleteEvent',
  FETCH_TYPES: 'events/fetchEventTypes',
  FETCH_TAGS: 'events/fetchTags',
  PUBLISH: 'events/publish',
  UNPUBLISH: 'events/unpublish',
}

export interface EventsState {
  all: Array<TddEvent>,
  event: TddEvent | null,
  eventTypes: Array<string>,
  tags: Array<string>,
}

export default {
  namespaced: true,

  state: (): EventsState => ({
    all: [],
    event: null,
    eventTypes: [],
    tags: [],
  }),

  actions: {
    async createEvent(context: any, params: Map<string, any>) {
      EventService.createEvent(params['params']);
    },

    async fetchAll(context: any) {
      EventService.allEvents().then(
        result => {
          context.commit('setEvents', result);
        },
        error => {
          console.error(error);
          context.commit('setEvents', []);
        });
    },

    async eventDetail(context: any, id: string) {
      return EventService.eventDetail(id).then(
        result => {
          context.commit('setEvent', result);
        },
        error => {
          console.error(error);
          context.commit('setEvent', null);
        });

    },

    async fetchEventTypes(context: any) {
      return EventService.eventTypes().then(
        result => {
          context.commit('setEventTypes', result);
        },
        error => {
          console.error(error);
          context.commit('setEventTypes', []);
        });
    },

    async fetchTags(context: any) {
      return EventService.tags().then(
        result => {
          context.commit('setTags', result);
        },
        error => {
          console.error(error);
          context.commit('setTags', []);
        });
    },

    async updateEvent(context: any, params: Map<string, any>) {
      EventService.updateEvent(params['id'] as string, params['params']);
    },
  },

  getters: {
    all: (state: EventsState) => state.all,
    event: (state: EventsState) => state.event,
    eventTypes: (state: EventsState) => state.eventTypes,
    tags: (state: EventsState) => state.tags,
  },


  mutations: {
    setEvent(state: EventsState, event: TddEvent) {
      state.event = event;
    },

    setEvents(state: EventsState, events: Array<TddEvent>) {
      state.all = events;
    },

    setEventTypes(state: EventsState, eventTypes: Array<string>) {
      state.eventTypes = eventTypes;
    },

    setTags(state: EventsState, tags: Array<string>) {
      state.tags = tags;
    },
  }
}


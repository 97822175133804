
import { User } from "@/models/User"
import { AuthService } from "@/services/api/auth-service"

export const AUTH_CURRENT_USER = 'auth/currentUser'
export const AUTH_IS_LOGGED_IN = 'auth/isLoggedIn'
export const AUTH_LOGIN = 'auth/login'
export const AUTH_LOGOUT = 'auth/logout'

export interface AuthState {
  user: User | null,
  loggedIn: boolean
}

// Carico l'utente salvato dal localStorage, se presente e lo inizializzo nello store
const localStorageUser = localStorage.getItem('user');
const storedUser = localStorageUser ? JSON.parse(localStorageUser) as User : null;

export default {
  namespaced: true,

  state: (): AuthState => ({
    loggedIn: storedUser != null,
    user: storedUser,
  }),

  actions: {
    async login(context: any, params: Map<string, any>) {
      const user: User = await AuthService.login(params)
      context.commit('loginSuccess', user)
    },

    async logout(context) {
      context.commit('logout');
    }
  },

  getters: {
    currentUser: (state: AuthState): User | null => state.user,

    isLoggedIn: (state: AuthState): boolean => state.loggedIn
  },

  mutations: {
    loginFailure(state: AuthState) {
      state.loggedIn = false;
      state.user = null;
    },

    loginSuccess(state: AuthState, user: User) {
      state.loggedIn = true
      state.user = user
    },

    logout(state: AuthState) {
      state.loggedIn = false;
      state.user = null;
    },
  }
}

import { AppState, HIDE_LOADER, SHOW_LOADER } from "@/store/modules/app";
import { Store } from "vuex";

const hideLoader = (store: Store<AppState>) => {
  return store.dispatch(HIDE_LOADER)
};
const showLoader = (store: Store<AppState>) => {
  return store.dispatch(SHOW_LOADER)
};

export {
  hideLoader,
  showLoader,
}

<template>
  <div
    class="
      container
      flex flex-wrap
      justify-content-center
      align-content-center
      h-full
      w-full
    "
  >
    <router-view />
  </div>
</template>

<style scoped>
.container {
  margin: auto;
  max-width: 1400px;
  min-width: 1400px;
  padding: 0 2rem;
}
</style>
import { ToastServiceMethods } from "primevue/toastservice";
import { hideLoader, showLoader } from "./loader";
import { showToast } from "./toast";

const sleep = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay))

const errorCallback = function (toast: ToastServiceMethods, err: any) {
  const errorMessage = Object.values(err.response.data.errors).join("\n");
  showToast(toast, { message: errorMessage, error: true });
}

export {
  errorCallback,
  hideLoader,
  showLoader,
  showToast,
  sleep,
}



import { Location } from "@/models/Location"
import { apiGet, apiPost, apiPut, apiDelete } from "./api-service";

export class LocationService {
  static async allLocations(): Promise<Array<Location>> {
    const response = await apiGet("/locations");
    return response.data.locations as Array<Location>;
  }

  static async createLocation(params: Map<string, any>): Promise<Location> {
    const response = await apiPost("/locations", params);
    return response.data.location as Location;
  }

  static async delete(id: string): Promise<null> {
    await apiDelete(`/locations/${id}`);
    return null;
  }

  static async updateLocation(id: string, params: Map<string, any>): Promise<Location> {
    const response = await apiPut(`/locations/${id}`, params);
    return response.data.location as Location;
  }
}

import { computed, defineComponent, ref } from "vue";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { AUTH_LOGIN } from "@/store/modules/auth";
import {
  errorCallback,
  hideLoader,
  showLoader,
  showToast,
} from "@/helpers/helpers";
import router from "@/router";

export default defineComponent({
  name: "LoginView",

  setup() {
    const store = useStore();
    const toast = useToast();

    /**
     * DATA
     */
    const enableFormValidation = ref(false);
    const form = ref({
      email: {
        error: "",
        rules: { required: true },
        val: "",
      },
      password: {
        error: "",
        rules: { required: true, min: 8 },
        val: "",
      },
    });

    /**
     * COMPUTED
     */
    const currentUser = computed(() => store.getters["auth/currentUser"]);
    const isFormValid = computed(() => {
      return form.value.email.error === "" && form.value.password.error === "";
    });

    /**
     * METHODS
     */
    const updateFormInputText = function ({ error, name, value }) {
      form.value[`${name}`].error = error;
      form.value[`${name}`].val = value;
    };

    const submit = function () {
      enableFormValidation.value = true;

      if (!isFormValid.value) {
        return;
      }

      const params = {
        email: form.value.email.val,
        password: form.value.password.val,
      };

      showLoader(store);

      store
        .dispatch(AUTH_LOGIN, params)
        .then(() => {
          hideLoader(store);
          // --> /eventi
          router.push({ name: "events" });
        })
        .catch((err) => {
          hideLoader(store);
          errorCallback(toast, err);
        });
    };

    return {
      // DATA
      enableFormValidation,
      form,

      // COMPUTED
      currentUser,
      isFormValid,

      // METHODS
      updateFormInputText,
      submit,
    };
  },
});


import { errorCallback, showToast } from "@/helpers/helpers";
import { hideLoader, showLoader } from "@/helpers/loader";
import { TddEvent } from "@/models/TddEvent";
import router from "@/router";
import { IS_LOADER_VISIBLE } from "@/store/modules/app";
import { EVENTS_ACTIONS, EVENTS_GETTERS } from "@/store/modules/events";
import {
  LOCATIONS_ACTIONS,
  LOCATIONS_GETTERS,
} from "@/store/modules/locations";
import {
  ORGANIZERS_ACTIONS,
  ORGANIZERS_GETTERS,
} from "@/store/modules/organizers";
import moment from "moment";
import { useToast } from "primevue/usetoast";
import { computed, defineComponent, nextTick, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "EventFormView",

  setup() {
    const store = useStore();
    const toast = useToast();

    /*
     * DATA
     */
    const minDate = new Date("05/09/2023");
    const maxDate = new Date("05/14/2036");
    const inputDateFormat = "dd/mm/yy -";
    const form = ref({
      bookable: {
        error: "",
        rules: { required: false },
        val: false,
      },

      alignment: {
        error: "",
        rules: { required: false },
        val: "center",
      },

      booking_url: {
        error: "",
        rules: { required: false },
        val: "",
      },

      image_url: {
        error: "",
        rules: { required: false },
        val: "" as string | null,
      },

      description: {
        error: "",
        rules: { required: false },
        val: "",
      },

      short_description: {
        error: "",
        rules: { required: false },
        val: "",
      },

      description_1: {
        error: "",
        rules: { required: false },
        val: "",
      },

      description_2: {
        error: "",
        rules: { required: false },
        val: "",
      },

      description_3: {
        error: "",
        rules: { required: false },
        val: "",
      },

      endAt: {
        error: "",
        rules: { required: true },
        val: null as Date | null,
      },


      eventType: {
        error: "",
        rules: { required: true },
        val: null as string | null,
      },

      location: {
        error: "",
        rules: { required: true },
        val: null as string | null,
      },

      published: {
        error: "",
        rules: { required: true },
        val: true,
      },

      organizer: {
        error: "",
        rules: { required: true },
        val: null as string | null,
      },

      startAt: {
        error: "",
        rules: { required: true },
        val: null as Date | null,
      },

      tags: {
        error: "",
        rules: { required: true },
        val: [] as Array<string>,
      },

      title: {
        error: "",
        rules: { required: true },
        val: "",
      },

      language: {
        error: "",
        rules: { required: false },
        val: [] as Array<string>,
      },

      event_code: {
        error: "",
        rules: { required: false },
        val: "",
      },

      streaming_url: {
        error: "",
        rules: { required: false },
        val: "",
      },

      important: {
        error: "",
        rules: { required: false },
        val: false,
      },
    });
    const languages = ref([
      {
      name: 'IT', value: 'it'
    },{
      name: 'EN', value: 'en'
    }]);

    const validateForm = ref(false);

    /*
     * COMPUTED
     */

    const event = computed(() => store.getters[EVENTS_GETTERS.EVENT]);
    const isFormValid = computed(() => {
      return (
        form.value.endAt.error === "" &&
        form.value.eventType.error === "" &&
        form.value.location.error === "" &&
        form.value.organizer.error === "" &&
        form.value.startAt.error === "" &&
        form.value.tags.error === "" &&
        form.value.title.error === "" &&
        form.value.booking_url.error === ""
      );
    });
    const isLoaderVisible = computed(() => store.getters[IS_LOADER_VISIBLE]);
    const locations = computed(() => store.getters[LOCATIONS_GETTERS.ALL]);
    const organizers = computed(() => store.getters[ORGANIZERS_GETTERS.ALL]);
    const eventTypes = computed(
      () => store.getters[EVENTS_GETTERS.EVENT_TYPES]
    );
    const tags = computed(
      () => store.getters[EVENTS_GETTERS.TAGS] as Array<Map<string, string>>
    );

    /*
     * METHODS
     */
    const changeBooking = async function () {
      form.value.booking_url.rules = { required: form.value.bookable.val };
    };

    const fetchEvent = async function () {
      return store.dispatch(EVENTS_ACTIONS.DETAIL, eventId);
    };

    const fetchEventTypes = async function () {
      return store.dispatch(EVENTS_ACTIONS.FETCH_TYPES);
    };

    const fetchTags = async function () {
      return store.dispatch(EVENTS_ACTIONS.FETCH_TAGS);
    };

    const fetchLocations = async function () {
      return store.dispatch(LOCATIONS_ACTIONS.FETCH_ALL);
    };

    const fetchOrganizers = async function () {
      return store.dispatch(ORGANIZERS_ACTIONS.FETCH_ALL);
    };

    const initForm = function (event: TddEvent | null) {
      if (event != null) {
        form.value.bookable.val = event.bookable;
        form.value.booking_url.rules = { required: event.bookable };
        form.value.booking_url.val = event.booking_url;
        form.value.image_url.val = event.image_url;
        form.value.endAt.val = new Date(event.end_at);
        form.value.description.val = event.description;
        debugger;
        form.value.important.val = event.important;
        form.value.short_description.val = event.short_description;
        form.value.description_1.val = event.description_1;
        form.value.description_2.val = event.description_2;
        form.value.description_3.val = event.description_3;
        form.value.eventType.val = event.event_type || null;
        form.value.location.val = event.location?.id || null;
        form.value.organizer.val = event.organizer?.id || null;
        form.value.published.val = event.published;
        form.value.startAt.val = new Date(event.start_at);
        form.value.tags.val = event.tags;
        form.value.title.val = event.title;
        form.value.alignment.val = event.alignment;
        form.value.language.val = event.language;
        form.value.event_code.val = event.event_code;
        form.value.streaming_url.val = event.streaming_url;
      }
    };

    // const buildFormData = function (
    //   formData,
    //   data: any,
    //   parentKey: string | null
    // ) {
    //   if (
    //     data &&
    //     typeof data === "object" &&
    //     !(data instanceof Date) &&
    //     !(data instanceof File)
    //   ) {
    //     Object.keys(data).forEach((key) => {
    //       buildFormData(
    //         formData,
    //         data[key],
    //         parentKey ? `${parentKey}[${key}]` : key
    //       );
    //     });
    //   } else {
    //     const value = data == null ? "" : data;

    //     formData.append(parentKey, value);
    //   }
    // };

    // const jsonToFormData = function (data) {
    //   const formData = new FormData();
    //   buildFormData(formData, data, null);
    //   return formData;
    // };

    // const selectAttachment = function (event) {
    //   form.value.cover = event.files[0];
    // };

    const submit = function () {
      validate();

      if (!isFormValid.value) {
        return;
      }
      debugger;
      const params = {
        event: {
          alignment: form.value.alignment.val,
          bookable: form.value.bookable.val,
          booking_url: form.value.booking_url.val,
          image_url: form.value.image_url.val,
          description: form.value.description.val,
          description_1: form.value.description_1.val,
          description_2: form.value.description_2.val,
          description_3: form.value.description_3.val,
          short_description: form.value.short_description.val,
          important: form.value.important.val,
          end_at: form.value.endAt.val,
          event_type: form.value.eventType.val,
          id: event?.value?.id,
          start_at: form.value.startAt.val,
          tags: form.value.tags.val,
          title: form.value.title.val,
          location_id: form.value.location.val,
          organizer_id: form.value.organizer.val,
          published: form.value.published.val,
          language: form.value.language.val,
          event_code: form.value.event_code.val,
          streaming_url: form.value.streaming_url.val
        },
      };

      // const formData = jsonToFormData(params);
      // if (form.value.cover != null) {
      //   formData.append("[event][cover]", form.value.cover!);
      // }

      showLoader(store);

      store
        .dispatch(
          eventId != null
            ? EVENTS_ACTIONS.UPDATE
            : EVENTS_ACTIONS.CREATE,
          { id: eventId, params: params }
        )
        .then(() => {
          hideLoader(store);
         // fetchEvent();

          showToast(toast, {
            message: "Evento salvato correttamente",
            title: "Evento",
          });

          router.push({ name: "events" });
        })
        .catch((err) => {
          hideLoader(store);
          errorCallback(toast, err);
        });
    };

    const updateFormInputText = function ({ error, name, value }) {
      form.value[`${name}`].error = error;
      form.value[`${name}`].val = value;
    };

    const validate = function () {
      if (form.value.endAt.val == null) {
        form.value.endAt.error = "Campo obbligatorio";
      } else {
        form.value.endAt.error = "";
      }

      if (form.value.eventType.val == null) {
        form.value.eventType.error = "Campo obbligatorio";
      } else {
        form.value.eventType.error = "";
      }

      if (form.value.location.val == null) {
        form.value.location.error = "Campo obbligatorio";
      } else {
        form.value.location.error = "";
      }

      if (form.value.organizer.val == null) {
        form.value.organizer.error = "Campo obbligatorio";
      } else {
        form.value.organizer.error = "";
      }

      if (form.value.startAt.val == null) {
        form.value.startAt.error = "Campo obbligatorio";
      } else {
        form.value.startAt.error = "";
      }

      if (form.value.tags.val.length == 0) {
        form.value.tags.error = "Campo obbligatorio";
      } else {
        form.value.tags.error = "";
      }

      // FIXME: if (form.value.bookable.val && form.value.booking_url.val === "") {
      //   debugger;
      //   form.value.booking_url.error = "Campo obbligatorio";
      // } else {
      //   form.value.booking_url.error = "";
      // }
    };

    /**
     * INIT
     */
    const eventId = router.currentRoute.value.params["id"];

    showLoader(store);
    nextTick(async () => {
      // DOM is now updated
      await fetchLocations();
      await fetchEventTypes();
      await fetchOrganizers();
      await fetchTags();

      if (eventId) {
        await fetchEvent().then(() => initForm(event.value as TddEvent));
      }

      hideLoader(store);
    });

    return {
      // UTILS
      moment,

      // DATA
      inputDateFormat,
      form,
      minDate,
      maxDate,
      validateForm,
      languages,
      eventId,

      // COMPUTED
      event,
      eventTypes,
      isLoaderVisible,
      locations,
      organizers,
      tags,

      // METHODS
      changeBooking,
      submit,
      updateFormInputText,
    };
  },
});


import { computed, defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "FormInputField",

  props: {
    disabled: {
      default: false,
      type: Boolean,
    },

    label: {
      required: true,
      type: String,
    },

    inputSize: {
      default: "default",
      type: String,
    },

    name: {
      required: true,
      type: String,
    },

    placeholder: {
      type: String,
      required: false,
    },

    rules: {
      required: true,
      type: Object,
    },

    type: {
      default: "",
      type: String,
    },

    validationEnabled: {
      default: false,
      type: Boolean,
    },

    val: {
      type: String,
      required: true,
    },

    width: {
      default: "w-100",
      type: String,
    },
  },

  emits: ["change"],

  setup(props, { emit }) {
    // DATA
    const input = ref(null);

    // COMPUTED
    const error = computed(() => {
      if (
        props.rules.required &&
        props.val !== undefined &&
        props.val.length === 0
      ) {
        return "Campo obbligatorio";
      }

      if (
        props.rules.min &&
        props.val != undefined &&
        props.val.length < props.rules.min
      ) {
        return `Inserisci almeno ${props.rules.min} caratteri`;
      }

      return "";
    });

    // LIFECYCLE HOOKS
    onMounted(() => {
      setTimeout(() => {
        // const inputText = input.value;
        // if (inputText != null) {
        //   (inputText as HTMLInputElement).focus();
        // }
      }, 500);
    });

    // METHODS
    function onChange(event: Event) {
      const target = event.target as HTMLInputElement;
      emit("change", { error: error, name: props.name, value: target.value });
    }

    return {
      // COMPUTED
      error,
      // DATA
      input,
      // METHODS
      onChange,
    };
  },
});

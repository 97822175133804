
import { defineComponent } from "vue";
import MainContent from "@/components/MainContent.vue";
import SidebarMenu from "@/components/SidebarMenu.vue";

export default defineComponent({
  name: "AdminLayout",

  components: {
    MainContent,
    SidebarMenu,
  },
});


import { Organizer } from "@/models/Organizer"
import { apiGet, apiDelete, apiPostMultipart, apiPost, apiPut } from "./api-service";

export class OrganizerService {
  static async allOrganizers(): Promise<Array<Organizer>> {
    const response = await apiGet("/organizers");
    return response.data.organizers as Array<Organizer>;
  }

  static async createOrganizer(params: Map<string, any>): Promise<Organizer> {
    const response = await apiPost("/organizers", params);
    return response.data.organizer as Organizer;
  }

  static async delete(id: string): Promise<null> {
    await apiDelete(`/organizers/${id}`);
    return null;
  }

  static async updateOrganizer(id: string, params: Map<string, any>): Promise<Organizer> {
    const response = await apiPut(`/organizers/${id}`, params);
    return response.data.organizer as Organizer;
  }
}

import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { AUTH_LOGOUT } from "@/store/modules/auth";
import router from "@/router";

export default defineComponent({
  name: "MainContentView",

  setup() {
    const store = useStore();

    /**
     * DATA
     */
    const menu = ref();

    const menuItems = [
      {
        label: "Esci",
        command: () => logout(),
      },
    ];

    /**
     * COMPUTED
     */
    const currentUser = computed(() => store.getters["auth/currentUser"]);
    const currentUserFirstLetters = computed(
      () =>
        `${currentUser.value.first_name[0]}${currentUser.value.last_name[0]}`
    );

    /**
     * METHODS
     */
    const logout = function () {
      store.dispatch(AUTH_LOGOUT).finally(() => {
        // --> Login
        router.push({
          name: "login",
        });
      });
    };

    const toggleMenu = (event: Event) => {
      menu.value.toggle(event);
    };

    return {
      // DATA
      menu,
      menuItems,

      // COMPUTED
      currentUser,
      currentUserFirstLetters,

      // METHODS
      toggleMenu,
    };
  },
});

import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import EventsListView from '@/views/events/EventsListView.vue'
import EventFormView from '@/views/events/eventForm/EventFormView.vue'
import LocationsListView from '@/views/locations/LocationsListView.vue'
import LoginView from '@/views/auth/LoginView.vue'
import OrganizerListView from '@/views/organizers/OrganizersListView.vue';
import { User } from '@/models/User'

const routes: Array<RouteRecordRaw> = [
  {
    component: EventsListView,
    name: 'root',
    meta: {
      layout: 'AdminLayout',
      requiresAuth: true
    },
    path: '/',
  },

  {
    component: LoginView,
    meta: {
      layout: 'AuthLayout',
      guest: true,
    },
    name: 'login',
    path: '/login',
  },

  // Eventi
  {
    component: EventsListView,
    name: 'events',
    meta: {
      layout: 'AdminLayout',
      requiresAuth: true
    },
    path: '/eventi',
  },

  {
    component: EventFormView,
    name: 'eventDetail',
    meta: {
      layout: 'AdminLayout',
      requiresAuth: true
    },
    path: '/eventi/:id',
  },

  {
    component: EventFormView,
    name: 'newEvent',
    meta: {
      layout: 'AdminLayout',
      requiresAuth: true
    },
    path: '/eventi/nuovo',
  },



  // Location
  {
    component: LocationsListView,
    name: 'locations',
    meta: {
      layout: 'AdminLayout',
      requiresAuth: true
    },
    path: '/location',
  },

  // Organizzatori
  {
    component: OrganizerListView,
    name: 'organizers',
    meta: {
      layout: 'AdminLayout',
      requiresAuth: true
    },
    path: '/organizzatori',
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router



router.afterEach((route) => {
  console.log(`[ROUTE]" ${route.path}`);

  // Redirect guest / loggato
  const localStorageUser = localStorage.getItem('user');
  const storedUser = localStorageUser ? JSON.parse(localStorageUser) as User : null;

  if (route.meta.requiresAuth && storedUser === null) {
    // non loggato
    router.push({ name: 'login' });
  }
  else {
    if (route.meta.guest && storedUser !== null) {
      // Loggato lo forzo in eventi
      router.push({ name: 'events' });
    }
  }
});

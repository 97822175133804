import { Organizer } from "@/models/Organizer";
import { OrganizerService } from "@/services/api/organizer-service";

export const ORGANIZERS_ACTIONS = {
  FETCH_ALL: 'organizers/fetchAll',
  CREATE: 'organizers/createOrganizer',
  UPDATE: 'organizers/updateOrganizer',
  DELETE: 'organizers/deleteOrganizer',
};

export const ORGANIZERS_GETTERS = {
  ALL: 'organizers/all',
}

export interface OrganizersState {
  all: Array<Organizer>,
}

export default {
  namespaced: true,

  state: (): OrganizersState => ({
    all: [],
  }),

  actions: {
    async fetchAll(context: any) {
      const result = await OrganizerService.allOrganizers().catch(error => {
        console.error(error);
        context.commit('setOrganizers', []);
      });


      context.commit('setOrganizers', result);
    },

    async createOrganizer(context: any, params: Map<string, any>) {
      await OrganizerService.createOrganizer(params);
    },

    async deleteOrganizer(context: any, id: string) {
      await OrganizerService.delete(id);
    },

    async updateOrganizer(context: any, params: Map<string, any>) {
      await OrganizerService.updateOrganizer(params['organizer']['id'] as string, params);
    }

  },

  getters: {
    all: (state: OrganizersState): Array<Organizer> => state.all,
  },

  mutations: {
    setOrganizers(state: OrganizersState, organizers: Array<Organizer>) {
      state.all = organizers;
    },
  }
}

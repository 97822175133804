import { TddEvent } from "@/models/TddEvent"
import { apiGet, apiPost, apiPostMultipart, apiPut, } from "./api-service";

export class EventService {
  static async createEvent(params: Map<string, any>): Promise<TddEvent> {
    const response = await apiPost("/events", params);
    return response.data.event as TddEvent;
  }

  static async updateEvent(id: string, params: Map<string, any>): Promise<TddEvent> {
    const response = await apiPut(`/events/${id}`, params);
    return response.data.event as TddEvent;
  }

  static async allEvents(): Promise<Array<TddEvent>> {
    const response = await apiGet("/events");
    return response.data.events as Array<TddEvent>;
  }

  static async eventDetail(id: string): Promise<TddEvent> {
    const response = await apiGet(`/events/${id}`);
    return response.data.event as TddEvent;
  }

  static async eventTypes(): Promise<Array<string>> {
    const response = await apiGet('/events/event_types');
    return response.data.event_types as Array<string>;
  }

  static async tags(): Promise<Array<string>> {
    const response = await apiGet('/events/tags');
    return response.data.tags as Array<string>;
  }
}
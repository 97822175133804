import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "full-h",
  style: {"overflow":"hidden"}
}
const _hoisted_2 = { class: "min-h-screen flex relative" }
const _hoisted_3 = { class: "col-2 xxl:col-1 min-h-screen" }
const _hoisted_4 = { class: "col-10 xxl:col-11 min-h-screen p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarMenu = _resolveComponent("SidebarMenu")!
  const _component_MainContent = _resolveComponent("MainContent")!
  const _component_Toast = _resolveComponent("Toast")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_SidebarMenu)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_MainContent)
        ])
      ])
    ]),
    _createVNode(_component_Toast, { position: "top-right" })
  ], 64))
}